import { animateSidebar } from "./animateSidebar";
import { getSidebarStateForScreenSize } from "./getSidebarStateForScreenSize";

export const setSidebarState = () => {
  const $sidebar = document.querySelector(".sidebar");
  if (!$sidebar) return;

  const state = getSidebarStateForScreenSize();
  animateSidebar(() => {
    // TODO: always close when changing screensize to mobile
    if (state === "open") {
      $sidebar.classList.add("open");
      $sidebar.classList.remove("closed");
    } else {
      $sidebar.classList.add("closed");
      $sidebar.classList.remove("open");
    }
  });
};
