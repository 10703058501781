import { initScrollbar } from "./initScrollbar";
import { setContainerSizes } from "./setContainerSizes";
import { updateNavabarItemPositions } from "./updateNavabarItemPositions";

// run after navbar is mounted to the DOM
export const initNavbar = () => {
  initScrollbar();
  updateNavabarItemPositions({ event: { type: "init" } });
  setContainerSizes({ init: true });
};
