import { animateSidebar } from "./animateSidebar";
import { setContainerSizes } from "./setContainerSizes";
import { isMobile } from "./isMobile";
import { sidebarStates } from "./sidebarStates";
import { rafRenderSidebar } from "./rafRenderSidebar";
import {
  ANIMATION_TIME,
  animator,
  getOffset,
  isInViewport,
  updateNavabarItemPositions,
} from "./updateNavabarItemPositions";

// need to handle repositioning of icons along the arc
export const toggleSidebar = (
  $sidebar = document.querySelector(".sidebar"),
) => {
  animateSidebar(() => {
    if (!$sidebar) throw new Error("No sidebar found");
    const openState = $sidebar.classList.toggle("open");
    $sidebar.classList.toggle("closed");

    // TODO: persist
    if (isMobile()) {
      sidebarStates.mobile = openState ? "open" : "closed";
    } else {
      sidebarStates.desktop = openState ? "open" : "closed";
    }
    setContainerSizes({ $sidebar, init: false });

    setTimeout(() => {
      const $viewport = document.querySelector(".sidebar-navigation-viewport");
      const $links = document.querySelectorAll(".sidebar-navigation-item");

      const viewportPosition = getOffset($viewport);

      $links.forEach(($l) => {
        const $mainLink = $l.querySelector(".sidebar-nav-link");
        const mainLinkInViewport = isInViewport(
          $mainLink,
          viewportPosition.top,
        );

        if (mainLinkInViewport) {
          $mainLink.classList.add("in-view");
          $mainLink.classList.remove("out-of-view", "disabled");
        } else {
          $mainLink.classList.remove("in-view");
          $mainLink.classList.add("out-of-view", "disabled");
        }
      });
    }, ANIMATION_TIME);

    // console.log("sidebarStates", {
    //   event: "toggle-sidebar",
    //   payload: { isOpen: openState },
    // });

    // rafRenderSidebar(ANIMATION_TIME, () => {
    // updateNavabarItemPositions({
    //   event: "toggle-sidebar",
    //   payload: { isOpen: openState },
    // });
    animator.queue(ANIMATION_TIME, {
      // event: "toggle-sidebar",
      // payload: { isOpen: openState },
    });
    // });
  });

  // setTimeout(() => {
  //   updateNavabarItemPositions({ event: "toggle-sidebar" });
  // }, 175);
  // setTimeout(() => {
  //   updateNavabarItemPositions({ event: "toggle-sidebar" });
  // }, ANIMATION_TIME);
};
