import { handleResize } from "./handleResize";
import { initDropdowns } from "./initDropdowns";
import { initNavbar } from "./initNavbar";
import { ANIMATION_TIME } from "./updateNavabarItemPositions";

let intervalSidebar = null;
let resizeEventAttached = false;

// let currentRoute = null;

// Sidebar Code for angular
export const ngInit = () => {
  if (intervalSidebar) return;

  intervalSidebar = setInterval(() => {
    const $sidebar = document.querySelector(".sidebar");
    if (!!$sidebar) {
      clearInterval(intervalSidebar);
      intervalSidebar = null;

      const alreadyInitialized = $sidebar.classList.contains("init");
      if (alreadyInitialized) {
        console.log("already initialized");
        return;
      }

      $sidebar.classList.add("init");

      // console.log("ng-init");

      initDropdowns();
      initNavbar();

      document
        .querySelectorAll(".sidebar-navigation-item.ng-init")
        .forEach(($link) => $link.classList.remove("ng-init"));

      if (!resizeEventAttached) {
        window.addEventListener("resize", handleResize);
        resizeEventAttached = true;
      }
    }
  }, 1000 / 60 / 3);
};

export const ngUpdate = () => {
  const $sidebar = document.querySelector(".sidebar");

  if (intervalSidebar) return;

  intervalSidebar = setInterval(() => {
    if (!!$sidebar) {
      clearInterval(intervalSidebar);
      intervalSidebar = null;

      $sidebar.classList.add("animating");

      initDropdowns();
      initNavbar();

      document.querySelectorAll(".sidebar-navigation-item").forEach(($l) => {
        $l.classList.remove("ng-init");
      });

      setTimeout(
        () => $sidebar.classList.remove("animating"),
        ANIMATION_TIME + 50,
      );
    }
  }, 1000 / 60 / 3);
};

export const rcInit = (newRoute = "rc") => {
  if (intervalSidebar) return;

  intervalSidebar = setInterval(() => {
    const $sidebar = document.querySelector(".sidebar");
    if (!!$sidebar) {
      clearInterval(intervalSidebar);
      intervalSidebar = null;

      const alreadyInitialized = $sidebar.classList.contains("init");
      if (alreadyInitialized) return;

      initDropdowns();
      initNavbar();

      document
        .querySelectorAll(".sidebar-navigation-item.ng-init")
        .forEach(($link) => $link.classList.remove("ng-init"));

      if (!resizeEventAttached) {
        window.addEventListener("resize", handleResize);
        resizeEventAttached = true;
      }
    }
  }, 1000 / 60 / 3);
};

// Sidebar Code for angular
export const ngInitOld = (subroute = false) => {
  if (intervalSidebar) return;

  intervalSidebar = setInterval(() => {
    if (!!document.querySelector(".sidebar")) {
      if (subroute) {
        const dropdowns = document.querySelectorAll(
          ".sidebar-navigation-dropdown",
        );

        dropdowns.forEach((d) => {
          d.classList.add("subroute-change");
        });

        setTimeout(() => {
          dropdowns.forEach((d) => {
            d.classList.remove("subroute-change");
          });
        }, ANIMATION_TIME);
      }

      clearInterval(intervalSidebar);
      intervalSidebar = null;
      initNavbar();
      initDropdowns();

      // TODO: check why timeout is needed. This workaround used to init dropdowns.
      // Without this, they don't position correctly.
      setTimeout(() => {
        repositionNavLinks({ event: "init" });
      });

      if (!resizeEventAttached) {
        window.addEventListener("resize", handleResize);
        resizeEventAttached = true;
      }
    }
  }, 1000 / 60 / 3);
};
