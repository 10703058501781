const SIZE_FACTOR = 1;

export const repositionNavLinks = ({ event }) => {
  // create the x, y coordinates for the nav items and pass
  // them as css variables for each of them individually

  const $arc = document.querySelector(".sidebar #arc");
  const $icon =
    document.querySelector(".sidebar .sidebar-timeline-item .icon") ??
    document.querySelector(".sidebar .sidebar-navigation-item .icon");
  if (!$arc || !$icon) return;

  const ellipseHeight = $arc.getBoundingClientRect().height;
  const computedStyles = getComputedStyle(document.body);
  const openEllipseWidth =
    (parseInt(computedStyles.getPropertyValue("--sidebar-arc-width-open")) ??
      77) * 2;
  const closedEllipseWidth =
    (parseInt(computedStyles.getPropertyValue("--sidebar-arc-width-closed")) ??
      30) * 2;
  const closedSidebarWidth =
    parseInt(
      computedStyles.getPropertyValue("--sidebar-mobile-width-closed"),
    ) ?? 30;

  const origin = { x: 0, y: ellipseHeight / 2 };

  const iconHeight = $icon.getBoundingClientRect().height;

  // TODO: convert to rem
  // TODO: set & get as css-vars
  const firstButtonY = 100;
  const verticalDistanceBetweenLinks = 80 * SIZE_FACTOR;

  // get list of nav links and set their x, y coords
  const navLinks = [
    ...document.querySelectorAll(".sidebar-timeline-item"),
    ...document.querySelectorAll(".sidebar-navigation-item"),
  ];

  let lastPositionY = firstButtonY;
  let lastPositionYWithoutDropdown = firstButtonY;
  navLinks.forEach(($link, index) => {
    // const topOffset = firstButtonY + index * verticalDistanceBetweenLinks;
    const topOffset = lastPositionY;

    const y = origin.y - (topOffset + iconHeight / 2);

    const $openedDropdown = $link.querySelector(
      ".sidebar-navigation-dropdown.open",
    );

    const openDropdownHeight = $openedDropdown
      ? $openedDropdown.style.getPropertyValue("--dropdown-height")
      : 0;

    // lastPositionY +=
    //   verticalDistanceBetweenLinks + parseInt(openDropdownHeight);

    const xOpen =
      (openEllipseWidth / 2) *
        Math.abs(1 - (y / (0.5 * ellipseHeight)) ** 2) ** 0.5 -
      iconHeight / 2;
    // Math.abs(
    //   (openEllipseWidth / 2) *
    //     Math.abs(1 - (y / (0.5 * ellipseHeight)) ** 2) ** 0.5,
    // ) -
    // iconHeight / 2;

    // center around arc
    // const xClosed =
    //   Math.abs(
    //     (closedEllipseWidth / 2) *
    //       Math.abs(1 - (y / (0.5 * ellipseHeight)) ** 2) ** 0.5,
    //   ) -
    //   iconHeight / 2;

    // center in sidebar
    const xClosed = closedSidebarWidth / 2 - iconHeight / 2;

    if ($openedDropdown) {
      // console.log("$openedDropdown", $openedDropdown);
      // set the x coords of the sub-items
      const subItems = [
        ...$openedDropdown.querySelectorAll(
          ".sidebar-navigation-subitem .sidebar-nav-link",
        ),
      ];

      // console.log("subItems", subItems);

      // margin + border
      const margin = 16;
      const border = 4;
      const subItemHeight = 19;

      // const dropdownPosition = $openedDropdown.getBoundingClientRect();
      const dropdownPosition = {
        y: lastPositionY + 65,
        x: xClosed + iconHeight / 2,
      };
      // console.log("lastPositionY", lastPositionY);
      // console.log("dropdownPosition", dropdownPosition.y);
      let subItemLastPositionY = dropdownPosition.y - border;
      subItems.forEach(($subItem, subItemIndex) => {
        // console.log("dropdownPosition", dropdownPosition);
        const position = $subItem.getBoundingClientRect();
        // debugger;
        const topOffset =
          subItemLastPositionY +
          margin +
          border +
          (!!subItemIndex ? border + subItemHeight : 0);

        // console.log("topOffset", topOffset, position, $subItem);

        subItemLastPositionY = topOffset;

        const y = origin.y - topOffset;

        // TODO: to y subtract half of the item height
        const x = Math.abs(
          (openEllipseWidth / 2) *
            Math.abs(1 - (y / (0.5 * ellipseHeight)) ** 2) ** 0.5,
        );

        const offsetX = x - dropdownPosition.x;

        // console.log(offsetX);
        $subItem.style.marginLeft = `${offsetX}px`;
        // $subItem.style.transition = "none";
      });
    }

    // TODO: convert to rem
    $link.style.setProperty("--open-x", `${xOpen}px`);
    $link.style.setProperty("--open-y", `${topOffset}px`);
    $link.style.setProperty("--closed-x", `${xClosed}px`);
    // $link.style.setProperty("--closed-y", `${topOffset}px`);

    $link.style.setProperty(
      "--closed-y",
      `${
        ellipseHeight / 2 +
        lastPositionYWithoutDropdown -
        firstButtonY -
        ((navLinks.length + 1) / 2) * verticalDistanceBetweenLinks
      }px`,
    );

    $link.classList.remove("ng-init");

    lastPositionY +=
      verticalDistanceBetweenLinks +
      (openDropdownHeight ? parseInt(openDropdownHeight) - 25 : 0);
    lastPositionYWithoutDropdown += verticalDistanceBetweenLinks;
  });
};
