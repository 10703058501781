import { updateNavabarItemPositions } from "./updateNavabarItemPositions";

export const initScrollbar = () => {
  window.s = new window.SimpleBar(
    document.querySelector(".sidebar-navigation-viewport"),
    { autoHide: false, scrollbarMaxSize: 125 },
  );

  const el = window.s.getScrollElement();
  if (el) {
    el.addEventListener("scroll", (e) =>
      updateNavabarItemPositions({ event: "scroll", payload: e }),
    );
  }
};
