import { ANIMATION_TIME } from "./updateNavabarItemPositions";

export const animateSidebar = (() => {
  const animating = new Set();

  return (callback) => {
    const $sidebar = document.querySelector(".sidebar");
    if (!$sidebar) return;

    const id = performance.now ? performance.now() : Date.now();

    animating.add(id);
    $sidebar.classList.add("animating");

    if (callback) callback();

    setTimeout(() => {
      animating.delete(id);
      if (!animating.size && $sidebar?.classList) {
        $sidebar.classList.remove("animating");
      }
    }, ANIMATION_TIME + 100);
  };
})();
