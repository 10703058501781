export const isInViewport = (element, viewportTop) => {
  const rect = element.getBoundingClientRect();

  return (
    rect.top >= viewportTop &&
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <=
      (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
};

export const getOffset = ($el) => {
  let _x = 0;
  let _y = 0;

  while ($el && !isNaN($el.offsetLeft) && !isNaN($el.offsetTop)) {
    _x += $el.offsetLeft - $el.scrollLeft;
    _y += $el.offsetTop - $el.scrollTop;
    $el = $el.offsetParent;
  }

  return { top: _y, left: _x };
};

export const ANIMATION_TIME = 300 + 50;

export const updateNavabarItemPositions = ({ event, payload }) => {
  const computedStyles = getComputedStyle(document.body);

  const $arc = document.querySelector(".sidebar #arc");
  const $viewport = document.querySelector(".sidebar-navigation-viewport");
  const $icon =
    document.querySelector(".sidebar .sidebar-timeline-item .icon") ??
    document.querySelector(".sidebar .sidebar-navigation-item .icon");

  if (!$arc || !$icon || !$viewport) return;

  const viewportPosition = getOffset($viewport);

  const openEllipseWidth =
    (parseInt(computedStyles.getPropertyValue("--sidebar-arc-width-open")) ??
      77) * 2;

  const ellipseHeight = $arc.getBoundingClientRect().height;
  const iconHeight = $icon.getBoundingClientRect().height;

  const sidebarIsOpen = !!document
    .querySelector(".sidebar")
    .classList.contains("open");

  let $openDropdowns = [];
  let openedDropdownHeights = 0;

  const $navLinks =
    $viewport.querySelectorAll(".sidebar-navigation-item") ?? [];

  $navLinks.forEach(($l, i) => {
    const $mainLink = $l.querySelector(".sidebar-nav-link");
    const mainLinkInViewport = isInViewport($mainLink, viewportPosition.top);

    let y =
      -1 *
      (ellipseHeight / 2 -
        // viewport start
        (-80 +
          // padding
          10 +
          getOffset($mainLink).top +
          $mainLink.offsetHeight / 2));

    let x =
      (Math.abs(
        (openEllipseWidth / 2) *
          Math.abs(1 - (y / (0.5 * ellipseHeight)) ** 2) ** 0.5,
      ) -
        iconHeight / 2) *
      (y > ellipseHeight / 2 ? -1 : 1);

    if (mainLinkInViewport) {
      $mainLink.classList.add("in-view");
      $mainLink.classList.remove("out-of-view", "disabled");
    } else {
      $mainLink.classList.remove("in-view");
      $mainLink.classList.add("out-of-view", "disabled");
    }

    $l.style.setProperty("--open-x", `${x}px`);

    if ($l.classList.contains("open-dropdown")) {
      if (sidebarIsOpen) {
        const $openDropdown = $l.querySelector(".sidebar-navigation-dropdown");
        if (!!$openDropdown?.children?.length) {
          $openDropdowns.push($openDropdown);

          openedDropdownHeights +=
            ($openDropdown.children.length ?? 0) * (27 + 8) + 8 * 2;
        }
      }

      const $subLinks = $l.querySelectorAll(".sidebar-navigation-subitem");

      $subLinks.forEach(($s) => {
        const subLinkInViewport = isInViewport($s, viewportPosition.top);

        const $subLink = $s.querySelector(".sidebar-nav-link");

        if (subLinkInViewport) {
          $subLink.classList.add("in-view");
          $subLink.classList.remove("out-of-view", "disabled");
        } else {
          $subLink.classList.remove("in-view");
          $subLink.classList.add("out-of-view", "disabled");
        }

        const subLinkY =
          -1 *
          (ellipseHeight / 2 -
            (-80 + 8 + 10 + 4 + getOffset($s).top + $s.offsetHeight / 2));

        let subLinkX =
          (Math.abs(
            (openEllipseWidth / 2) *
              Math.abs(1 - (subLinkY / (0.5 * ellipseHeight)) ** 2) ** 0.5,
          ) -
            16 / 2) *
          (subLinkY > ellipseHeight / 2 ? -1 : 1);

        $s.style.marginLeft = subLinkX + 20 + "px";
      });
    }
  });
};

export const animator = {
  // raf id
  _id: null,

  // current state: active/idle
  _active: false,

  // animations currently running
  _ids: new Set(),

  // when the last animation will have finished
  _endTime: 0,

  queue: (duration, { event, payload }) => {
    const id = Math.random() + Date.now();
    animator._ids.add(id);

    if (!animator._active) {
      animator._start(Date.now() + duration);
    }
  },

  _start: (endTime) => {
    if (endTime > animator._endTime) {
      animator._endTime = endTime;
    } else {
      return;
    }

    animator._active = true;
    animator.id = requestAnimationFrame((currentTime) =>
      updateNavabarItemPositions({
        event: "raf",
        payload: {
          currentTime,
          endTime: animator._endTime,
        },
      }),
    );
    animator._animation();
  },

  // self-stopping loop that runs as long as an animatin is active
  _animation: (currentTime = performance?.now() ?? Date.now()) => {
    if (currentTime > animator._endTime) {
      animator._active = false;
      animator._stop();
    } else {
      animator._active = true;

      animator._id = requestAnimationFrame((nextTime) => {
        // updateNavabarItemPositions({
        //   // event: "raf",
        //   // payload: {
        //   //   currentTime: nextTime,
        //   //   endTime: animator._endTime,
        //   // },
        //   event: "toggle-sidebar",
        //   // payload: { isOpen: openState },
        //   payload: { isOpen: undefined },
        // });
        animator._animation(nextTime);
      });
    }
  },

  _stop: () => {
    animator._active = false;
    animator._endTime = 0;

    cancelAnimationFrame(animator._id);
  },
};
