import { ANIMATION_TIME } from "./updateNavabarItemPositions";

export const setContainerSizes = ({
  $sidebar = document.querySelector(".sidebar"),
  init = false,
}) => {
  const $wrapper = document.querySelector(".page-wrapper-internal-page");

  if (init) {
    $sidebar.classList.add("instant");
  }

  if (!init) {
    $wrapper.classList.add("animate");
  } else {
    $wrapper.classList.remove("animate");
  }

  const newWidth = getComputedStyle($sidebar).getPropertyValue(
    "--sidebar-container-width",
  );
  $wrapper.style.setProperty("--sidebar-container-width", newWidth);

  if (init) {
    $sidebar.classList.remove("instant");
  }
  if (!init) {
    setTimeout(() => {
      $wrapper.classList.remove("animate");
    }, ANIMATION_TIME);
  }
};
