import { animateSidebar } from "./animateSidebar";
import { repositionNavLinks } from "./repositionNavLinks";
import {
  disableAllSubmenuLinks,
  enableOpenedSubmenuLinks,
} from "./submenuAccessibility";
import {
  ANIMATION_TIME,
  updateNavabarItemPositions,
} from "./updateNavabarItemPositions";

export const toggleDropdown = (e) => {
  e.preventDefault();

  e.currentTarget.parentElement.classList.toggle("open-dropdown");

  // no longer used (using above class to see if the dropdown's open)
  e.currentTarget.parentElement
    .querySelector(".sidebar-navigation-dropdown")
    ?.classList.toggle("open");

  animateSidebar(() =>
    repositionNavLinks({
      event: {
        type: "toggle-dropdown",
        payload: { target: e.currentTarget.parentElement },
      },
    }),
  );
};

// For angular app
export const openDropdown = (target) => {
  document.querySelectorAll(".has-dropdown").forEach((el) => {
    el.classList.remove("open-dropdown");
  });
  document
    .querySelectorAll(".sidebar-navigation-dropdown.open")
    .forEach((el) => {
      el.classList.remove("open");
    });

  disableAllSubmenuLinks();

  target.parentElement.classList.add("open-dropdown");

  // no longer used (using above class to see if the dropdown's open)
  target.parentElement
    .querySelector(".sidebar-navigation-dropdown")
    ?.classList.add("open");

  enableOpenedSubmenuLinks();

  // updateNavabarItemPositions({ event: "open-dropdown" });
  let start = Date.now();
  let timer = setInterval(() => {
    const now = Date.now();

    if (now - start > ANIMATION_TIME) {
      // console.log("timer cleared");
      clearInterval(timer);
      timer = null;
    } else {
      // console.log("timer running");
      updateNavabarItemPositions({ event: "dropdown" });
    }
  }, 1000 / 60);
};
