import { isMobile } from "./isMobile";
import { setContainerSizes } from "./setContainerSizes";
import { setSidebarState } from "./setSidebarState";
import { sidebarStates } from "./sidebarStates";
import { updateNavabarItemPositions } from "./updateNavabarItemPositions";

let lastScreenSize = isMobile() ? "mobile" : "desktop";
let r = false;

export const handleResize = () => {
  const newScreenSize = isMobile() ? "mobile" : "desktop";
  if (!r) {
    r = true;
    requestAnimationFrame(() => {
      // set screensize specific dropdown state (mobile or desktop)
      if (lastScreenSize !== newScreenSize) {
        // set navbar open/close state based on new screen size
        setSidebarState();
        lastScreenSize = isMobile() ? "mobile" : "desktop";

        if (lastScreenSize === "mobile") {
          // if going from open mobile to desktop, open desktop navbar
          if (sidebarStates.mobile === "open") {
            sidebarStates.desktop = "open";
          }

          // close sidebar on mobile always
          sidebarStates.mobile = "closed";
        }
      }

      // TODO: record magnitude of resize. If it's extremely large, animate. This
      // happens when maximizing and minimizing the window. For minor resize, animation
      // creates a lot of lag because it will try to make small adjustment take 0.3sec
      setContainerSizes({ init: true });
      // repositionNavLinks({ event: { type: "resize" } });
      updateNavabarItemPositions({ event: { type: "resize" } });
      r = false;
    });
  }
};
