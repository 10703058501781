export const disableAllSubmenuLinks = () => {
  document
    .querySelectorAll(".sidebar-navigation-dropdown .sidebar-nav-link")
    .forEach((el) => {
      el.setAttribute("tabindex", "-1");
    });
};

export const enableOpenedSubmenuLinks = () => {
  document
    .querySelectorAll(".sidebar-navigation-dropdown.open .sidebar-nav-link")
    .forEach((el) => {
      el.setAttribute("tabindex", "0");
    });
};
