import {
  disableAllSubmenuLinks,
  enableOpenedSubmenuLinks,
} from "./submenuAccessibility";
import {
  ANIMATION_TIME,
  animator,
  updateNavabarItemPositions,
} from "./updateNavabarItemPositions";

const updateDueToDropdown = (endTime) => {
  requestAnimationFrame(() => {
    updateNavabarItemPositions({ event: "dropdown" });

    if (Date.now() <= endTime) {
      updateDueToDropdown(endTime);
    }
  });
};

export const initDropdowns = () => {
  const $sidebar = document.querySelector(".sidebar");
  if (!$sidebar) throw new Error("No sidebar found");

  const $dropdowns = document.querySelectorAll(".sidebar-navigation-dropdown");
  if (!$dropdowns) return;

  $dropdowns.forEach((dropdowns) => {
    if (dropdowns) {
      dropdowns.style.setProperty(
        "--dropdown-height",
        `${(dropdowns.children.length ?? 0) * (27 + 8) + 8 * 2}px`,
      );

      $sidebar.classList.remove("animating");

      dropdowns.addEventListener("transitionstart", (e) => {
        if (e.isTrusted && e.propertyName === "max-height") {
          // updateDueToDropdown(Date.now() + ANIMATION_TIME);
          // animator.queue(ANIMATION_TIME, { event: "dropdown" });
        }
      });

      // dropdowns.addEventListener("transitionend", (e) => {
      //   if (e.isTrusted && e.propertyName === "max-height") {
      //     // $sidebar.classList.add("animating");
      //     // updateNavabarItemPositions({ event: "dropdown" });
      //     // $sidebar.classList.remove("animating");
      //   }
      // });
    }
  });

  disableAllSubmenuLinks();
  enableOpenedSubmenuLinks();
};
// export const initDropdowns = () => {
//   const $dropdowns = document.querySelectorAll(".sidebar-navigation-dropdown");
//   if (!$dropdowns) return;

//   $dropdowns.forEach(($d) => {
//     const $c = $d.cloneNode(true);

//     Object.assign($c.style, {
//       position: "relative",
//       left: "-100vw",
//       maxHeight: "none",
//     });

//     $d.parentElement.appendChild($c);
//     const dropdownHeight = $c.clientHeight;
//     $c.remove();

//     $d.style.setProperty("--dropdown-height", `${dropdownHeight}px`);
//   });

//   disableAllSubmenuLinks();
//   enableOpenedSubmenuLinks();
// };
